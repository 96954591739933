import { FC, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  children: ReactNode;
};

export const Portal: FC<PortalProps> = (props) => {
  const { children } = props;
  const [container] = useState<HTMLDivElement>(() =>
    document.createElement('div'),
  );

  useEffect(() => {
    document.body.appendChild(container);

    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, []);

  return ReactDOM.createPortal(children, container);
};
