// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SkeletonFilter-module__container--i0WhLqSn{max-width:50%;min-width:280px;display:grid;align-items:center;grid-template-columns:1fr 2fr 1.45fr .75fr;gap:34px 10.2112676056%}.SkeletonFilter-module__container--i0WhLqSn>div:nth-child(4){height:16px !important}", "",{"version":3,"sources":["webpack://./src/shared/ui/Skeleton/components/SkeletonFilter/SkeletonFilter.module.scss"],"names":[],"mappings":"AAEA,4CACE,aAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,0CAAA,CACA,uBAAA,CAEA,6DACE,sBAAA","sourcesContent":["$elementWidth: 568;\n\n.container {\n  max-width: 50%;\n  min-width: 280px;\n  display: grid;\n  align-items: center;\n  grid-template-columns: 1fr 2fr 1.45fr 0.75fr;\n  gap: 34px calc(58% / ($elementWidth / 100));\n\n  & > div:nth-child(4) {\n    height: 16px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SkeletonFilter-module__container--i0WhLqSn"
};
export default ___CSS_LOADER_EXPORT___;
