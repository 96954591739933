import { useEffect } from 'react';

import { RootState } from '../../app/model/store';

import { useAppSelector } from './store';

const themeSelector = (state: RootState): 'light' | 'dark' => state.theme.theme;

export const useTheme = () => {
  const theme = useAppSelector(themeSelector);
  useEffect(() => {
    const documentEl = document.documentElement;

    if (theme === 'dark') {
      documentEl.classList.add('dark');
    }
    if (documentEl.classList.contains('dark') && theme === 'light') {
      documentEl.classList.remove('dark');
    }
  }, [theme]);
};
