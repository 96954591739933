import { both, filter } from 'ramda';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { isROIISONumbersIncludesToothISONumber } from './isROIISONumbersIncludesToothISONumber';
import { isToothReportIDEquals } from './isToothReportIDEquals';

export const filterTeethByReportIDAndROIISONumbers = (
  teeth: Tooth[],
  reportID: string,
  ROIISONumbers: number[],
) =>
  filter(
    both(
      isROIISONumbersIncludesToothISONumber(ROIISONumbers),
      isToothReportIDEquals(reportID),
    ),
  )(teeth);
