// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConditionToggleContainer-module__container--0F5tnJt9{display:flex;flex-direction:column;gap:12px}", "",{"version":3,"sources":["webpack://./src/entities/condition/ui/ConditionToggleContainer/ConditionToggleContainer.module.scss"],"names":[],"mappings":"AAAA,sDACE,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ConditionToggleContainer-module__container--0F5tnJt9"
};
export default ___CSS_LOADER_EXPORT___;
