/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import { Observable } from 'rxjs';
import { Message, Link, Content } from '../../api/messenger/dto_message';
import { Empty } from '../../google/protobuf/empty';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';

export const protobufPackage = 'com.diagnocat.messenger';

/**
 * json examples:
 *
 * {
 * "Filter":  {
 * "Links":  [
 * {
 * "EntityType":  "c46i0hhgnh7rnaht1dog",
 * "EntityID":  "c46i0hhgnh7rnaht1do0",
 * "LinkType":  "c46i0hhgnh7rnaht1dp0"
 * }
 * ]
 * }
 * }
 *
 * {
 * "More":  {
 * "Latest":  {},
 * "Count":  "10"
 * }
 * }
 */
export interface MessagesStreamQuery {
  Filter: MessagesStreamQueryFilter | undefined;
  Count: number;
}

export interface MessagesStreamQueryFilter {
  Links: Link[];
}

export interface MessagesStreamEvent {
  Historical: Message | undefined;
  Created: Message | undefined;
  Edited: Message | undefined;
  Deleted: Message | undefined;
  Counts: Counts | undefined;
  Ping: Empty | undefined;
}

export interface Counts {
  /** int64 Unread = 2; */
  Total: number;
}

export interface GetMoreReq {
  Filter: MessagesStreamQueryFilter | undefined;
  Latest: Empty | undefined;
  BeforeMessageID: string | undefined;
  AfterMessageID: string | undefined;
  Count: number;
}

export interface GetMoreResp {
  Messages: Message[];
  Counts: Counts | undefined;
}

export interface EditMessageReq {
  ID: string;
  Content: Content[];
}

export interface DeleteMessageReq {
  ID: string;
}

export interface CountMessagesReq {
  Links: Link[];
}

/** WebSocket-dedicated messages */
export interface Error {
  Text: string;
}

function createBaseMessagesStreamQuery(): MessagesStreamQuery {
  return { Filter: undefined, Count: 0 };
}

export const MessagesStreamQuery = {
  encode(
    message: MessagesStreamQuery,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Filter !== undefined) {
      MessagesStreamQueryFilter.encode(
        message.Filter,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Count !== 0) {
      writer.uint32(16).int64(message.Count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessagesStreamQuery {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessagesStreamQuery();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Filter = MessagesStreamQueryFilter.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.Count = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MessagesStreamQuery {
    return {
      Filter: isSet(object.Filter)
        ? MessagesStreamQueryFilter.fromJSON(object.Filter)
        : undefined,
      Count: isSet(object.Count) ? Number(object.Count) : 0,
    };
  },

  toJSON(message: MessagesStreamQuery): unknown {
    const obj: any = {};
    message.Filter !== undefined &&
      (obj.Filter = message.Filter
        ? MessagesStreamQueryFilter.toJSON(message.Filter)
        : undefined);
    message.Count !== undefined && (obj.Count = Math.round(message.Count));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MessagesStreamQuery>, I>>(
    object: I,
  ): MessagesStreamQuery {
    const message = createBaseMessagesStreamQuery();
    message.Filter =
      object.Filter !== undefined && object.Filter !== null
        ? MessagesStreamQueryFilter.fromPartial(object.Filter)
        : undefined;
    message.Count = object.Count ?? 0;
    return message;
  },
};

function createBaseMessagesStreamQueryFilter(): MessagesStreamQueryFilter {
  return { Links: [] };
}

export const MessagesStreamQueryFilter = {
  encode(
    message: MessagesStreamQueryFilter,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Links) {
      Link.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MessagesStreamQueryFilter {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessagesStreamQueryFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 16:
          message.Links.push(Link.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MessagesStreamQueryFilter {
    return {
      Links: Array.isArray(object?.Links)
        ? object.Links.map((e: any) => Link.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MessagesStreamQueryFilter): unknown {
    const obj: any = {};
    if (message.Links) {
      obj.Links = message.Links.map((e) => (e ? Link.toJSON(e) : undefined));
    } else {
      obj.Links = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MessagesStreamQueryFilter>, I>>(
    object: I,
  ): MessagesStreamQueryFilter {
    const message = createBaseMessagesStreamQueryFilter();
    message.Links = object.Links?.map((e) => Link.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMessagesStreamEvent(): MessagesStreamEvent {
  return {
    Historical: undefined,
    Created: undefined,
    Edited: undefined,
    Deleted: undefined,
    Counts: undefined,
    Ping: undefined,
  };
}

export const MessagesStreamEvent = {
  encode(
    message: MessagesStreamEvent,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Historical !== undefined) {
      Message.encode(message.Historical, writer.uint32(10).fork()).ldelim();
    }
    if (message.Created !== undefined) {
      Message.encode(message.Created, writer.uint32(18).fork()).ldelim();
    }
    if (message.Edited !== undefined) {
      Message.encode(message.Edited, writer.uint32(26).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Message.encode(message.Deleted, writer.uint32(34).fork()).ldelim();
    }
    if (message.Counts !== undefined) {
      Counts.encode(message.Counts, writer.uint32(42).fork()).ldelim();
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MessagesStreamEvent {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessagesStreamEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Historical = Message.decode(reader, reader.uint32());
          break;
        case 2:
          message.Created = Message.decode(reader, reader.uint32());
          break;
        case 3:
          message.Edited = Message.decode(reader, reader.uint32());
          break;
        case 4:
          message.Deleted = Message.decode(reader, reader.uint32());
          break;
        case 5:
          message.Counts = Counts.decode(reader, reader.uint32());
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MessagesStreamEvent {
    return {
      Historical: isSet(object.Historical)
        ? Message.fromJSON(object.Historical)
        : undefined,
      Created: isSet(object.Created)
        ? Message.fromJSON(object.Created)
        : undefined,
      Edited: isSet(object.Edited)
        ? Message.fromJSON(object.Edited)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Message.fromJSON(object.Deleted)
        : undefined,
      Counts: isSet(object.Counts) ? Counts.fromJSON(object.Counts) : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: MessagesStreamEvent): unknown {
    const obj: any = {};
    message.Historical !== undefined &&
      (obj.Historical = message.Historical
        ? Message.toJSON(message.Historical)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Message.toJSON(message.Created)
        : undefined);
    message.Edited !== undefined &&
      (obj.Edited = message.Edited
        ? Message.toJSON(message.Edited)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Message.toJSON(message.Deleted)
        : undefined);
    message.Counts !== undefined &&
      (obj.Counts = message.Counts ? Counts.toJSON(message.Counts) : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MessagesStreamEvent>, I>>(
    object: I,
  ): MessagesStreamEvent {
    const message = createBaseMessagesStreamEvent();
    message.Historical =
      object.Historical !== undefined && object.Historical !== null
        ? Message.fromPartial(object.Historical)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Message.fromPartial(object.Created)
        : undefined;
    message.Edited =
      object.Edited !== undefined && object.Edited !== null
        ? Message.fromPartial(object.Edited)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Message.fromPartial(object.Deleted)
        : undefined;
    message.Counts =
      object.Counts !== undefined && object.Counts !== null
        ? Counts.fromPartial(object.Counts)
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBaseCounts(): Counts {
  return { Total: 0 };
}

export const Counts = {
  encode(
    message: Counts,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Total !== 0) {
      writer.uint32(8).int64(message.Total);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Counts {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCounts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Total = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Counts {
    return {
      Total: isSet(object.Total) ? Number(object.Total) : 0,
    };
  },

  toJSON(message: Counts): unknown {
    const obj: any = {};
    message.Total !== undefined && (obj.Total = Math.round(message.Total));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Counts>, I>>(object: I): Counts {
    const message = createBaseCounts();
    message.Total = object.Total ?? 0;
    return message;
  },
};

function createBaseGetMoreReq(): GetMoreReq {
  return {
    Filter: undefined,
    Latest: undefined,
    BeforeMessageID: undefined,
    AfterMessageID: undefined,
    Count: 0,
  };
}

export const GetMoreReq = {
  encode(
    message: GetMoreReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Filter !== undefined) {
      MessagesStreamQueryFilter.encode(
        message.Filter,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Latest !== undefined) {
      Empty.encode(message.Latest, writer.uint32(18).fork()).ldelim();
    }
    if (message.BeforeMessageID !== undefined) {
      writer.uint32(26).string(message.BeforeMessageID);
    }
    if (message.AfterMessageID !== undefined) {
      writer.uint32(34).string(message.AfterMessageID);
    }
    if (message.Count !== 0) {
      writer.uint32(136).int64(message.Count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMoreReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMoreReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Filter = MessagesStreamQueryFilter.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.Latest = Empty.decode(reader, reader.uint32());
          break;
        case 3:
          message.BeforeMessageID = reader.string();
          break;
        case 4:
          message.AfterMessageID = reader.string();
          break;
        case 17:
          message.Count = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetMoreReq {
    return {
      Filter: isSet(object.Filter)
        ? MessagesStreamQueryFilter.fromJSON(object.Filter)
        : undefined,
      Latest: isSet(object.Latest) ? Empty.fromJSON(object.Latest) : undefined,
      BeforeMessageID: isSet(object.BeforeMessageID)
        ? String(object.BeforeMessageID)
        : undefined,
      AfterMessageID: isSet(object.AfterMessageID)
        ? String(object.AfterMessageID)
        : undefined,
      Count: isSet(object.Count) ? Number(object.Count) : 0,
    };
  },

  toJSON(message: GetMoreReq): unknown {
    const obj: any = {};
    message.Filter !== undefined &&
      (obj.Filter = message.Filter
        ? MessagesStreamQueryFilter.toJSON(message.Filter)
        : undefined);
    message.Latest !== undefined &&
      (obj.Latest = message.Latest ? Empty.toJSON(message.Latest) : undefined);
    message.BeforeMessageID !== undefined &&
      (obj.BeforeMessageID = message.BeforeMessageID);
    message.AfterMessageID !== undefined &&
      (obj.AfterMessageID = message.AfterMessageID);
    message.Count !== undefined && (obj.Count = Math.round(message.Count));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetMoreReq>, I>>(
    object: I,
  ): GetMoreReq {
    const message = createBaseGetMoreReq();
    message.Filter =
      object.Filter !== undefined && object.Filter !== null
        ? MessagesStreamQueryFilter.fromPartial(object.Filter)
        : undefined;
    message.Latest =
      object.Latest !== undefined && object.Latest !== null
        ? Empty.fromPartial(object.Latest)
        : undefined;
    message.BeforeMessageID = object.BeforeMessageID ?? undefined;
    message.AfterMessageID = object.AfterMessageID ?? undefined;
    message.Count = object.Count ?? 0;
    return message;
  },
};

function createBaseGetMoreResp(): GetMoreResp {
  return { Messages: [], Counts: undefined };
}

export const GetMoreResp = {
  encode(
    message: GetMoreResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Messages) {
      Message.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.Counts !== undefined) {
      Counts.encode(message.Counts, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMoreResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMoreResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Messages.push(Message.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Counts = Counts.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetMoreResp {
    return {
      Messages: Array.isArray(object?.Messages)
        ? object.Messages.map((e: any) => Message.fromJSON(e))
        : [],
      Counts: isSet(object.Counts) ? Counts.fromJSON(object.Counts) : undefined,
    };
  },

  toJSON(message: GetMoreResp): unknown {
    const obj: any = {};
    if (message.Messages) {
      obj.Messages = message.Messages.map((e) =>
        e ? Message.toJSON(e) : undefined,
      );
    } else {
      obj.Messages = [];
    }
    message.Counts !== undefined &&
      (obj.Counts = message.Counts ? Counts.toJSON(message.Counts) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GetMoreResp>, I>>(
    object: I,
  ): GetMoreResp {
    const message = createBaseGetMoreResp();
    message.Messages =
      object.Messages?.map((e) => Message.fromPartial(e)) || [];
    message.Counts =
      object.Counts !== undefined && object.Counts !== null
        ? Counts.fromPartial(object.Counts)
        : undefined;
    return message;
  },
};

function createBaseEditMessageReq(): EditMessageReq {
  return { ID: '', Content: [] };
}

export const EditMessageReq = {
  encode(
    message: EditMessageReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    for (const v of message.Content) {
      Content.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EditMessageReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEditMessageReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 4:
          message.Content.push(Content.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): EditMessageReq {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Content: Array.isArray(object?.Content)
        ? object.Content.map((e: any) => Content.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EditMessageReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    if (message.Content) {
      obj.Content = message.Content.map((e) =>
        e ? Content.toJSON(e) : undefined,
      );
    } else {
      obj.Content = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<EditMessageReq>, I>>(
    object: I,
  ): EditMessageReq {
    const message = createBaseEditMessageReq();
    message.ID = object.ID ?? '';
    message.Content = object.Content?.map((e) => Content.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteMessageReq(): DeleteMessageReq {
  return { ID: '' };
}

export const DeleteMessageReq = {
  encode(
    message: DeleteMessageReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteMessageReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteMessageReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteMessageReq {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
    };
  },

  toJSON(message: DeleteMessageReq): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteMessageReq>, I>>(
    object: I,
  ): DeleteMessageReq {
    const message = createBaseDeleteMessageReq();
    message.ID = object.ID ?? '';
    return message;
  },
};

function createBaseCountMessagesReq(): CountMessagesReq {
  return { Links: [] };
}

export const CountMessagesReq = {
  encode(
    message: CountMessagesReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Links) {
      Link.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountMessagesReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountMessagesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Links.push(Link.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CountMessagesReq {
    return {
      Links: Array.isArray(object?.Links)
        ? object.Links.map((e: any) => Link.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CountMessagesReq): unknown {
    const obj: any = {};
    if (message.Links) {
      obj.Links = message.Links.map((e) => (e ? Link.toJSON(e) : undefined));
    } else {
      obj.Links = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CountMessagesReq>, I>>(
    object: I,
  ): CountMessagesReq {
    const message = createBaseCountMessagesReq();
    message.Links = object.Links?.map((e) => Link.fromPartial(e)) || [];
    return message;
  },
};

function createBaseError(): Error {
  return { Text: '' };
}

export const Error = {
  encode(message: Error, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Text !== '') {
      writer.uint32(10).string(message.Text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Error {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Text = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Error {
    return {
      Text: isSet(object.Text) ? String(object.Text) : '',
    };
  },

  toJSON(message: Error): unknown {
    const obj: any = {};
    message.Text !== undefined && (obj.Text = message.Text);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Error>, I>>(object: I): Error {
    const message = createBaseError();
    message.Text = object.Text ?? '';
    return message;
  },
};

export interface Messenger {
  /** published at '/api/messages/stream_ws' */
  Stream(
    request: DeepPartial<MessagesStreamQuery>,
    metadata?: grpc.Metadata,
  ): Observable<MessagesStreamEvent>;
  GetMore(
    request: DeepPartial<GetMoreReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetMoreResp>;
  Post(
    request: DeepPartial<Message>,
    metadata?: grpc.Metadata,
  ): Promise<Message>;
  Edit(
    request: DeepPartial<EditMessageReq>,
    metadata?: grpc.Metadata,
  ): Promise<Message>;
  Delete(
    request: DeepPartial<DeleteMessageReq>,
    metadata?: grpc.Metadata,
  ): Promise<Empty>;
  Count(
    request: DeepPartial<CountMessagesReq>,
    metadata?: grpc.Metadata,
  ): Promise<Counts>;
}

export class MessengerClientImpl implements Messenger {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.Stream = this.Stream.bind(this);
    this.GetMore = this.GetMore.bind(this);
    this.Post = this.Post.bind(this);
    this.Edit = this.Edit.bind(this);
    this.Delete = this.Delete.bind(this);
    this.Count = this.Count.bind(this);
  }

  Stream(
    request: DeepPartial<MessagesStreamQuery>,
    metadata?: grpc.Metadata,
  ): Observable<MessagesStreamEvent> {
    return this.rpc.invoke(
      MessengerStreamDesc,
      MessagesStreamQuery.fromPartial(request),
      metadata,
    );
  }

  GetMore(
    request: DeepPartial<GetMoreReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetMoreResp> {
    return this.rpc.unary(
      MessengerGetMoreDesc,
      GetMoreReq.fromPartial(request),
      metadata,
    );
  }

  Post(
    request: DeepPartial<Message>,
    metadata?: grpc.Metadata,
  ): Promise<Message> {
    return this.rpc.unary(
      MessengerPostDesc,
      Message.fromPartial(request),
      metadata,
    );
  }

  Edit(
    request: DeepPartial<EditMessageReq>,
    metadata?: grpc.Metadata,
  ): Promise<Message> {
    return this.rpc.unary(
      MessengerEditDesc,
      EditMessageReq.fromPartial(request),
      metadata,
    );
  }

  Delete(
    request: DeepPartial<DeleteMessageReq>,
    metadata?: grpc.Metadata,
  ): Promise<Empty> {
    return this.rpc.unary(
      MessengerDeleteDesc,
      DeleteMessageReq.fromPartial(request),
      metadata,
    );
  }

  Count(
    request: DeepPartial<CountMessagesReq>,
    metadata?: grpc.Metadata,
  ): Promise<Counts> {
    return this.rpc.unary(
      MessengerCountDesc,
      CountMessagesReq.fromPartial(request),
      metadata,
    );
  }
}

export const MessengerDesc = {
  serviceName: 'com.diagnocat.messenger.Messenger',
};

export const MessengerStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'Stream',
  service: MessengerDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return MessagesStreamQuery.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...MessagesStreamEvent.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const MessengerGetMoreDesc: UnaryMethodDefinitionish = {
  methodName: 'GetMore',
  service: MessengerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetMoreReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...GetMoreResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const MessengerPostDesc: UnaryMethodDefinitionish = {
  methodName: 'Post',
  service: MessengerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Message.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Message.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const MessengerEditDesc: UnaryMethodDefinitionish = {
  methodName: 'Edit',
  service: MessengerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return EditMessageReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Message.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const MessengerDeleteDesc: UnaryMethodDefinitionish = {
  methodName: 'Delete',
  service: MessengerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteMessageReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Empty.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const MessengerCountDesc: UnaryMethodDefinitionish = {
  methodName: 'Count',
  service: MessengerDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CountMessagesReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...Counts.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
