import { find } from 'ramda';

import { PatientDoctor } from '@/shared/api/protocol_gen/model/dto_patient';

const isPatientDoctorWithId =
  (doctorID: string) =>
  ({ UserID }: PatientDoctor) =>
    UserID === doctorID;

export const findPatientDoctorById = (
  doctorID: string,
  patientDoctors: PatientDoctor[],
) => find(isPatientDoctorWithId(doctorID), patientDoctors);
