import { filter } from 'ramda';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { isROIISONumbersIncludesToothISONumber } from './isROIISONumbersIncludesToothISONumber';

export const filterTeethByROIISONumbers = (
  teeth: Tooth[],
  ROIISONumbers: number[],
) => filter(isROIISONumbersIncludesToothISONumber(ROIISONumbers))(teeth);
