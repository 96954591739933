/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { CBCTSeriesGeometryData } from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

export interface ReportCBCTGP {
  OrthoScreening: ReportCBCTGPOrthoScreening | undefined;
  CropGeometryData: CBCTSeriesGeometryData | undefined;
}

export interface ReportCBCTGPOrthoScreening {
  /** "angles_malocclusion" */
  AnglesClass: ReportCBCTGPOrthoScreening_OrthoAnglesClass;
  /** dropped "lower" and "upper" substructures */
  Crowding: ReportCBCTGPOrthoScreening_OrthoToothPair[];
  Spacing: ReportCBCTGPOrthoScreening_OrthoToothPair[];
  CephalometricConditions: ReportCBCTGPOrthoScreening_OrthoCephalometricCondition[];
}

export enum ReportCBCTGPOrthoScreening_OrthoAnglesClass {
  /** AnglesClassUnidentified - null; cannot be calculated */
  AnglesClassUnidentified = 0,
  /** AnglesClass1 - "first_class_malocclusion"; norm */
  AnglesClass1 = 1,
  /** AnglesClass2 - "second_class_malocclusion"; */
  AnglesClass2 = 2,
  /** AnglesClass3 - "third_class_malocclusion"; */
  AnglesClass3 = 3,
  UNRECOGNIZED = -1,
}

export function reportCBCTGPOrthoScreening_OrthoAnglesClassFromJSON(
  object: any,
): ReportCBCTGPOrthoScreening_OrthoAnglesClass {
  switch (object) {
    case 0:
    case 'AnglesClassUnidentified':
      return ReportCBCTGPOrthoScreening_OrthoAnglesClass.AnglesClassUnidentified;
    case 1:
    case 'AnglesClass1':
      return ReportCBCTGPOrthoScreening_OrthoAnglesClass.AnglesClass1;
    case 2:
    case 'AnglesClass2':
      return ReportCBCTGPOrthoScreening_OrthoAnglesClass.AnglesClass2;
    case 3:
    case 'AnglesClass3':
      return ReportCBCTGPOrthoScreening_OrthoAnglesClass.AnglesClass3;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportCBCTGPOrthoScreening_OrthoAnglesClass.UNRECOGNIZED;
  }
}

export function reportCBCTGPOrthoScreening_OrthoAnglesClassToJSON(
  object: ReportCBCTGPOrthoScreening_OrthoAnglesClass,
): string {
  switch (object) {
    case ReportCBCTGPOrthoScreening_OrthoAnglesClass.AnglesClassUnidentified:
      return 'AnglesClassUnidentified';
    case ReportCBCTGPOrthoScreening_OrthoAnglesClass.AnglesClass1:
      return 'AnglesClass1';
    case ReportCBCTGPOrthoScreening_OrthoAnglesClass.AnglesClass2:
      return 'AnglesClass2';
    case ReportCBCTGPOrthoScreening_OrthoAnglesClass.AnglesClass3:
      return 'AnglesClass3';
    case ReportCBCTGPOrthoScreening_OrthoAnglesClass.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum ReportCBCTGPOrthoScreening_OrthoConditionCode {
  /** UnspecifiedCondition - unacceptable value */
  UnspecifiedCondition = 0,
  NormalMaxillaryPosition = 2000,
  MaxillaryRetroPosition = 2001,
  MaxillaryAntePosition = 2002,
  NormalMandibularPosition = 2010,
  MandibularRetroPosition = 2011,
  MandibularAntePosition = 2012,
  NormalMaxillaryAndMandibularSkeletalBasesRelation = 2020,
  MesialMaxillaryAndMandibularSkeletalBasesRelation = 2021,
  DistalMaxillaryAndMandibularSkeletalBasesRelation = 2022,
  NormalInclination = 2030,
  AnteInclination = 2031,
  RetroInclination = 2032,
  NormalDivergence = 2040,
  HypoDivergence = 2041,
  HyperDivergence = 2042,
  UpperIncisorNormalPositionAccordingToU1SnAngle = 2050,
  UpperIncisorRetrusionAccordingToU1SnAngle = 2051,
  UpperIncisorProtrusionAccordingToU1SnAngle = 2052,
  UpperIncisorNormalPositionAccordingToU1PpAngle = 2060,
  UpperIncisorRetrusionAccordingToU1PpAngle = 2061,
  UpperIncisorProtrusionAccordingToU1PpAngle = 2062,
  LowerIncisorNormalPosition = 2070,
  LowerIncisorRetrusion = 2071,
  LowerIncisorProtrusion = 2072,
  NormalOverjet = 2080,
  IncreasedOverjet = 2081,
  DecreasedOverjet = 2082,
  AnteriorCrossbite = 2083,
  NormalOverbite = 2090,
  IncreasedOverbite = 2091,
  DecreasedOverbite = 2092,
  OpenBite = 2093,
  UNRECOGNIZED = -1,
}

export function reportCBCTGPOrthoScreening_OrthoConditionCodeFromJSON(
  object: any,
): ReportCBCTGPOrthoScreening_OrthoConditionCode {
  switch (object) {
    case 0:
    case 'UnspecifiedCondition':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.UnspecifiedCondition;
    case 2000:
    case 'NormalMaxillaryPosition':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalMaxillaryPosition;
    case 2001:
    case 'MaxillaryRetroPosition':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.MaxillaryRetroPosition;
    case 2002:
    case 'MaxillaryAntePosition':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.MaxillaryAntePosition;
    case 2010:
    case 'NormalMandibularPosition':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalMandibularPosition;
    case 2011:
    case 'MandibularRetroPosition':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.MandibularRetroPosition;
    case 2012:
    case 'MandibularAntePosition':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.MandibularAntePosition;
    case 2020:
    case 'NormalMaxillaryAndMandibularSkeletalBasesRelation':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalMaxillaryAndMandibularSkeletalBasesRelation;
    case 2021:
    case 'MesialMaxillaryAndMandibularSkeletalBasesRelation':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.MesialMaxillaryAndMandibularSkeletalBasesRelation;
    case 2022:
    case 'DistalMaxillaryAndMandibularSkeletalBasesRelation':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.DistalMaxillaryAndMandibularSkeletalBasesRelation;
    case 2030:
    case 'NormalInclination':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalInclination;
    case 2031:
    case 'AnteInclination':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.AnteInclination;
    case 2032:
    case 'RetroInclination':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.RetroInclination;
    case 2040:
    case 'NormalDivergence':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalDivergence;
    case 2041:
    case 'HypoDivergence':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.HypoDivergence;
    case 2042:
    case 'HyperDivergence':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.HyperDivergence;
    case 2050:
    case 'UpperIncisorNormalPositionAccordingToU1SnAngle':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorNormalPositionAccordingToU1SnAngle;
    case 2051:
    case 'UpperIncisorRetrusionAccordingToU1SnAngle':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorRetrusionAccordingToU1SnAngle;
    case 2052:
    case 'UpperIncisorProtrusionAccordingToU1SnAngle':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorProtrusionAccordingToU1SnAngle;
    case 2060:
    case 'UpperIncisorNormalPositionAccordingToU1PpAngle':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorNormalPositionAccordingToU1PpAngle;
    case 2061:
    case 'UpperIncisorRetrusionAccordingToU1PpAngle':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorRetrusionAccordingToU1PpAngle;
    case 2062:
    case 'UpperIncisorProtrusionAccordingToU1PpAngle':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorProtrusionAccordingToU1PpAngle;
    case 2070:
    case 'LowerIncisorNormalPosition':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.LowerIncisorNormalPosition;
    case 2071:
    case 'LowerIncisorRetrusion':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.LowerIncisorRetrusion;
    case 2072:
    case 'LowerIncisorProtrusion':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.LowerIncisorProtrusion;
    case 2080:
    case 'NormalOverjet':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalOverjet;
    case 2081:
    case 'IncreasedOverjet':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.IncreasedOverjet;
    case 2082:
    case 'DecreasedOverjet':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.DecreasedOverjet;
    case 2083:
    case 'AnteriorCrossbite':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.AnteriorCrossbite;
    case 2090:
    case 'NormalOverbite':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalOverbite;
    case 2091:
    case 'IncreasedOverbite':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.IncreasedOverbite;
    case 2092:
    case 'DecreasedOverbite':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.DecreasedOverbite;
    case 2093:
    case 'OpenBite':
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.OpenBite;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportCBCTGPOrthoScreening_OrthoConditionCode.UNRECOGNIZED;
  }
}

export function reportCBCTGPOrthoScreening_OrthoConditionCodeToJSON(
  object: ReportCBCTGPOrthoScreening_OrthoConditionCode,
): string {
  switch (object) {
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.UnspecifiedCondition:
      return 'UnspecifiedCondition';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalMaxillaryPosition:
      return 'NormalMaxillaryPosition';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.MaxillaryRetroPosition:
      return 'MaxillaryRetroPosition';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.MaxillaryAntePosition:
      return 'MaxillaryAntePosition';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalMandibularPosition:
      return 'NormalMandibularPosition';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.MandibularRetroPosition:
      return 'MandibularRetroPosition';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.MandibularAntePosition:
      return 'MandibularAntePosition';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalMaxillaryAndMandibularSkeletalBasesRelation:
      return 'NormalMaxillaryAndMandibularSkeletalBasesRelation';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.MesialMaxillaryAndMandibularSkeletalBasesRelation:
      return 'MesialMaxillaryAndMandibularSkeletalBasesRelation';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.DistalMaxillaryAndMandibularSkeletalBasesRelation:
      return 'DistalMaxillaryAndMandibularSkeletalBasesRelation';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalInclination:
      return 'NormalInclination';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.AnteInclination:
      return 'AnteInclination';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.RetroInclination:
      return 'RetroInclination';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalDivergence:
      return 'NormalDivergence';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.HypoDivergence:
      return 'HypoDivergence';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.HyperDivergence:
      return 'HyperDivergence';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorNormalPositionAccordingToU1SnAngle:
      return 'UpperIncisorNormalPositionAccordingToU1SnAngle';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorRetrusionAccordingToU1SnAngle:
      return 'UpperIncisorRetrusionAccordingToU1SnAngle';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorProtrusionAccordingToU1SnAngle:
      return 'UpperIncisorProtrusionAccordingToU1SnAngle';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorNormalPositionAccordingToU1PpAngle:
      return 'UpperIncisorNormalPositionAccordingToU1PpAngle';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorRetrusionAccordingToU1PpAngle:
      return 'UpperIncisorRetrusionAccordingToU1PpAngle';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.UpperIncisorProtrusionAccordingToU1PpAngle:
      return 'UpperIncisorProtrusionAccordingToU1PpAngle';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.LowerIncisorNormalPosition:
      return 'LowerIncisorNormalPosition';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.LowerIncisorRetrusion:
      return 'LowerIncisorRetrusion';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.LowerIncisorProtrusion:
      return 'LowerIncisorProtrusion';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalOverjet:
      return 'NormalOverjet';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.IncreasedOverjet:
      return 'IncreasedOverjet';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.DecreasedOverjet:
      return 'DecreasedOverjet';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.AnteriorCrossbite:
      return 'AnteriorCrossbite';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.NormalOverbite:
      return 'NormalOverbite';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.IncreasedOverbite:
      return 'IncreasedOverbite';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.DecreasedOverbite:
      return 'DecreasedOverbite';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.OpenBite:
      return 'OpenBite';
    case ReportCBCTGPOrthoScreening_OrthoConditionCode.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface ReportCBCTGPOrthoScreening_OrthoToothPair {
  FirstToothNumberISO: number;
  SecondToothNumberISO: number;
}

export interface ReportCBCTGPOrthoScreening_OrthoCephalometricCondition {
  /** what we measured (angle, distance, etc.) */
  Rule: string;
  /** result of measurement */
  Value: number;
  Unit: string;
  ConditionCode: ReportCBCTGPOrthoScreening_OrthoConditionCode;
}

function createBaseReportCBCTGP(): ReportCBCTGP {
  return { OrthoScreening: undefined, CropGeometryData: undefined };
}

export const ReportCBCTGP = {
  encode(
    message: ReportCBCTGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrthoScreening !== undefined) {
      ReportCBCTGPOrthoScreening.encode(
        message.OrthoScreening,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.CropGeometryData !== undefined) {
      CBCTSeriesGeometryData.encode(
        message.CropGeometryData,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportCBCTGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrthoScreening = ReportCBCTGPOrthoScreening.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.CropGeometryData = CBCTSeriesGeometryData.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTGP {
    return {
      OrthoScreening: isSet(object.OrthoScreening)
        ? ReportCBCTGPOrthoScreening.fromJSON(object.OrthoScreening)
        : undefined,
      CropGeometryData: isSet(object.CropGeometryData)
        ? CBCTSeriesGeometryData.fromJSON(object.CropGeometryData)
        : undefined,
    };
  },

  toJSON(message: ReportCBCTGP): unknown {
    const obj: any = {};
    message.OrthoScreening !== undefined &&
      (obj.OrthoScreening = message.OrthoScreening
        ? ReportCBCTGPOrthoScreening.toJSON(message.OrthoScreening)
        : undefined);
    message.CropGeometryData !== undefined &&
      (obj.CropGeometryData = message.CropGeometryData
        ? CBCTSeriesGeometryData.toJSON(message.CropGeometryData)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTGP>, I>>(
    object: I,
  ): ReportCBCTGP {
    const message = createBaseReportCBCTGP();
    message.OrthoScreening =
      object.OrthoScreening !== undefined && object.OrthoScreening !== null
        ? ReportCBCTGPOrthoScreening.fromPartial(object.OrthoScreening)
        : undefined;
    message.CropGeometryData =
      object.CropGeometryData !== undefined && object.CropGeometryData !== null
        ? CBCTSeriesGeometryData.fromPartial(object.CropGeometryData)
        : undefined;
    return message;
  },
};

function createBaseReportCBCTGPOrthoScreening(): ReportCBCTGPOrthoScreening {
  return {
    AnglesClass: 0,
    Crowding: [],
    Spacing: [],
    CephalometricConditions: [],
  };
}

export const ReportCBCTGPOrthoScreening = {
  encode(
    message: ReportCBCTGPOrthoScreening,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.AnglesClass !== 0) {
      writer.uint32(8).int32(message.AnglesClass);
    }
    for (const v of message.Crowding) {
      ReportCBCTGPOrthoScreening_OrthoToothPair.encode(
        v!,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    for (const v of message.Spacing) {
      ReportCBCTGPOrthoScreening_OrthoToothPair.encode(
        v!,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    for (const v of message.CephalometricConditions) {
      ReportCBCTGPOrthoScreening_OrthoCephalometricCondition.encode(
        v!,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportCBCTGPOrthoScreening {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTGPOrthoScreening();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AnglesClass = reader.int32() as any;
          break;
        case 2:
          message.Crowding.push(
            ReportCBCTGPOrthoScreening_OrthoToothPair.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        case 3:
          message.Spacing.push(
            ReportCBCTGPOrthoScreening_OrthoToothPair.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        case 4:
          message.CephalometricConditions.push(
            ReportCBCTGPOrthoScreening_OrthoCephalometricCondition.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTGPOrthoScreening {
    return {
      AnglesClass: isSet(object.AnglesClass)
        ? reportCBCTGPOrthoScreening_OrthoAnglesClassFromJSON(
            object.AnglesClass,
          )
        : 0,
      Crowding: Array.isArray(object?.Crowding)
        ? object.Crowding.map((e: any) =>
            ReportCBCTGPOrthoScreening_OrthoToothPair.fromJSON(e),
          )
        : [],
      Spacing: Array.isArray(object?.Spacing)
        ? object.Spacing.map((e: any) =>
            ReportCBCTGPOrthoScreening_OrthoToothPair.fromJSON(e),
          )
        : [],
      CephalometricConditions: Array.isArray(object?.CephalometricConditions)
        ? object.CephalometricConditions.map((e: any) =>
            ReportCBCTGPOrthoScreening_OrthoCephalometricCondition.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ReportCBCTGPOrthoScreening): unknown {
    const obj: any = {};
    message.AnglesClass !== undefined &&
      (obj.AnglesClass = reportCBCTGPOrthoScreening_OrthoAnglesClassToJSON(
        message.AnglesClass,
      ));
    if (message.Crowding) {
      obj.Crowding = message.Crowding.map((e) =>
        e ? ReportCBCTGPOrthoScreening_OrthoToothPair.toJSON(e) : undefined,
      );
    } else {
      obj.Crowding = [];
    }
    if (message.Spacing) {
      obj.Spacing = message.Spacing.map((e) =>
        e ? ReportCBCTGPOrthoScreening_OrthoToothPair.toJSON(e) : undefined,
      );
    } else {
      obj.Spacing = [];
    }
    if (message.CephalometricConditions) {
      obj.CephalometricConditions = message.CephalometricConditions.map((e) =>
        e
          ? ReportCBCTGPOrthoScreening_OrthoCephalometricCondition.toJSON(e)
          : undefined,
      );
    } else {
      obj.CephalometricConditions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportCBCTGPOrthoScreening>, I>>(
    object: I,
  ): ReportCBCTGPOrthoScreening {
    const message = createBaseReportCBCTGPOrthoScreening();
    message.AnglesClass = object.AnglesClass ?? 0;
    message.Crowding =
      object.Crowding?.map((e) =>
        ReportCBCTGPOrthoScreening_OrthoToothPair.fromPartial(e),
      ) || [];
    message.Spacing =
      object.Spacing?.map((e) =>
        ReportCBCTGPOrthoScreening_OrthoToothPair.fromPartial(e),
      ) || [];
    message.CephalometricConditions =
      object.CephalometricConditions?.map((e) =>
        ReportCBCTGPOrthoScreening_OrthoCephalometricCondition.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseReportCBCTGPOrthoScreening_OrthoToothPair(): ReportCBCTGPOrthoScreening_OrthoToothPair {
  return { FirstToothNumberISO: 0, SecondToothNumberISO: 0 };
}

export const ReportCBCTGPOrthoScreening_OrthoToothPair = {
  encode(
    message: ReportCBCTGPOrthoScreening_OrthoToothPair,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FirstToothNumberISO !== 0) {
      writer.uint32(8).uint32(message.FirstToothNumberISO);
    }
    if (message.SecondToothNumberISO !== 0) {
      writer.uint32(16).uint32(message.SecondToothNumberISO);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportCBCTGPOrthoScreening_OrthoToothPair {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportCBCTGPOrthoScreening_OrthoToothPair();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FirstToothNumberISO = reader.uint32();
          break;
        case 2:
          message.SecondToothNumberISO = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportCBCTGPOrthoScreening_OrthoToothPair {
    return {
      FirstToothNumberISO: isSet(object.FirstToothNumberISO)
        ? Number(object.FirstToothNumberISO)
        : 0,
      SecondToothNumberISO: isSet(object.SecondToothNumberISO)
        ? Number(object.SecondToothNumberISO)
        : 0,
    };
  },

  toJSON(message: ReportCBCTGPOrthoScreening_OrthoToothPair): unknown {
    const obj: any = {};
    message.FirstToothNumberISO !== undefined &&
      (obj.FirstToothNumberISO = Math.round(message.FirstToothNumberISO));
    message.SecondToothNumberISO !== undefined &&
      (obj.SecondToothNumberISO = Math.round(message.SecondToothNumberISO));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportCBCTGPOrthoScreening_OrthoToothPair>, I>,
  >(object: I): ReportCBCTGPOrthoScreening_OrthoToothPair {
    const message = createBaseReportCBCTGPOrthoScreening_OrthoToothPair();
    message.FirstToothNumberISO = object.FirstToothNumberISO ?? 0;
    message.SecondToothNumberISO = object.SecondToothNumberISO ?? 0;
    return message;
  },
};

function createBaseReportCBCTGPOrthoScreening_OrthoCephalometricCondition(): ReportCBCTGPOrthoScreening_OrthoCephalometricCondition {
  return { Rule: '', Value: 0, Unit: '', ConditionCode: 0 };
}

export const ReportCBCTGPOrthoScreening_OrthoCephalometricCondition = {
  encode(
    message: ReportCBCTGPOrthoScreening_OrthoCephalometricCondition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Rule !== '') {
      writer.uint32(10).string(message.Rule);
    }
    if (message.Value !== 0) {
      writer.uint32(17).double(message.Value);
    }
    if (message.Unit !== '') {
      writer.uint32(26).string(message.Unit);
    }
    if (message.ConditionCode !== 0) {
      writer.uint32(32).int32(message.ConditionCode);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportCBCTGPOrthoScreening_OrthoCephalometricCondition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseReportCBCTGPOrthoScreening_OrthoCephalometricCondition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Rule = reader.string();
          break;
        case 2:
          message.Value = reader.double();
          break;
        case 3:
          message.Unit = reader.string();
          break;
        case 4:
          message.ConditionCode = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): ReportCBCTGPOrthoScreening_OrthoCephalometricCondition {
    return {
      Rule: isSet(object.Rule) ? String(object.Rule) : '',
      Value: isSet(object.Value) ? Number(object.Value) : 0,
      Unit: isSet(object.Unit) ? String(object.Unit) : '',
      ConditionCode: isSet(object.ConditionCode)
        ? reportCBCTGPOrthoScreening_OrthoConditionCodeFromJSON(
            object.ConditionCode,
          )
        : 0,
    };
  },

  toJSON(
    message: ReportCBCTGPOrthoScreening_OrthoCephalometricCondition,
  ): unknown {
    const obj: any = {};
    message.Rule !== undefined && (obj.Rule = message.Rule);
    message.Value !== undefined && (obj.Value = message.Value);
    message.Unit !== undefined && (obj.Unit = message.Unit);
    message.ConditionCode !== undefined &&
      (obj.ConditionCode = reportCBCTGPOrthoScreening_OrthoConditionCodeToJSON(
        message.ConditionCode,
      ));
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportCBCTGPOrthoScreening_OrthoCephalometricCondition>,
      I
    >,
  >(object: I): ReportCBCTGPOrthoScreening_OrthoCephalometricCondition {
    const message =
      createBaseReportCBCTGPOrthoScreening_OrthoCephalometricCondition();
    message.Rule = object.Rule ?? '';
    message.Value = object.Value ?? 0;
    message.Unit = object.Unit ?? '';
    message.ConditionCode = object.ConditionCode ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
