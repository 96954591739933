// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RefreshPage-module__container--fWZRopsh{position:fixed;left:20px;bottom:20px;padding:16px 24px 16px 64px;max-width:360px;background:var(--bg4);box-shadow:0 0 20px rgba(var(--purpletext1-rgb), 0.08);border-radius:16px;z-index:1000}\n\n[dir=\"rtl\"] .RefreshPage-module__container--fWZRopsh{left:auto;right:20px;padding:16px 64px 16px 24px}\n\n.RefreshPage-module__refreshIcon--v4Trkj3F{position:absolute;top:16px;left:16px;color:var(--purpleflat)}\n\n[dir=\"rtl\"] .RefreshPage-module__refreshIcon--v4Trkj3F{left:auto;right:16px}.RefreshPage-module__title--DByTMtXg{margin-bottom:8px}.RefreshPage-module__body--wjQ8wdPs{margin-bottom:12px}", "",{"version":3,"sources":["webpack://./src/widgets/RefreshPage/ui/RefreshPage.module.scss"],"names":[],"mappings":"AAAA,yCACE,cAAA,CACA,SAAA,CACA,WAAA,CACA,2BAAA,CACA,eAAA,CACA,qBAAA,CACA,sDAAA,CACA,kBAAA,CACA,YAAA;;AATF,qDAEE,SAAA,CAAA,UAAA,CAEA,2BAKA;;AAGF,2CACE,iBAAA,CACA,QAAA,CACA,SAAA,CACA,uBAAA;;AAJF,uDAGE,SAAA,CAAA,UACA,CAGF,qCACE,iBAAA,CAGF,oCACE,kBAAA","sourcesContent":[".container {\n  position: fixed;\n  left: 20px;\n  bottom: 20px;\n  padding: 16px 24px 16px 64px;\n  max-width: 360px;\n  background: var(--bg4);\n  box-shadow: 0 0 20px rgba(var(--purpletext1-rgb), 0.08);\n  border-radius: 16px;\n  z-index: 1000;\n}\n\n.refreshIcon {\n  position: absolute;\n  top: 16px;\n  left: 16px;\n  color: var(--purpleflat);\n}\n\n.title {\n  margin-bottom: 8px;\n}\n\n.body {\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RefreshPage-module__container--fWZRopsh",
	"refreshIcon": "RefreshPage-module__refreshIcon--v4Trkj3F",
	"title": "RefreshPage-module__title--DByTMtXg",
	"body": "RefreshPage-module__body--wjQ8wdPs"
};
export default ___CSS_LOADER_EXPORT___;
