import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { authModel } from '@/entities/auth';

export const useAuth = () => {
  const isAuth = useSelector(authModel.selectors.selectIsAuth);

  return useMemo(() => isAuth, [isAuth]);
};
