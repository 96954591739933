// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toast-module__container--Z1Pnko\\+B{display:flex}\n\n.Toast-module__icon--JO-gLZcD{margin:21px 0 0 21px}\n\n[dir=\"rtl\"] .Toast-module__icon--JO-gLZcD{margin:21px 21px 0 0}.Toast-module__body--qI\\+YlxWe{padding:16px}.Toast-module__toast--Ho56URYP{box-shadow:0 0 20px rgb(var(--purpleflat-rgb), 8%) !important;border-radius:16px !important;padding:0 !important}", "",{"version":3,"sources":["webpack://./src/shared/ui/Toast/Toast.module.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA;;AAGF,8BACE,oBAAA;;AADF,0CACE,oBAAA,CAGF,+BACE,YAAA,CAGF,+BACE,6DAAA,CACA,6BAAA,CACA,oBAAA","sourcesContent":[".container {\n  display: flex;\n}\n\n.icon {\n  margin: 21px 0 0 21px;\n}\n\n.body {\n  padding: 16px;\n}\n\n.toast {\n  box-shadow: 0 0 20px rgb(var(--purpleflat-rgb), 8%) !important;\n  border-radius: 16px !important;\n  padding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Toast-module__container--Z1Pnko+B",
	"icon": "Toast-module__icon--JO-gLZcD",
	"body": "Toast-module__body--qI+YlxWe",
	"toast": "Toast-module__toast--Ho56URYP"
};
export default ___CSS_LOADER_EXPORT___;
