// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorText-module__container--LR4E5tDB{color:var(--redrose)}\n\n.ErrorText-module__container--LR4E5tDB.ErrorText-module__withInput--Q320Q5sX{margin-left:16px}\n\n[dir=\"rtl\"] .ErrorText-module__container--LR4E5tDB.ErrorText-module__withInput--Q320Q5sX{margin-left:0;margin-right:16px}", "",{"version":3,"sources":["webpack://./src/shared/ui/ErrorText/ErrorText.module.scss"],"names":[],"mappings":"AAAA,uCACE,oBAAA;;AAEA,6EACE,gBAAA;;AADF,yFACE,aAAA,CAAA,iBAAA","sourcesContent":[".container {\n  color: var(--redrose);\n\n  &.withInput {\n    margin-left: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ErrorText-module__container--LR4E5tDB",
	"withInput": "ErrorText-module__withInput--Q320Q5sX"
};
export default ___CSS_LOADER_EXPORT___;
