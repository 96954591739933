/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.diagnocat.model';

export interface MedicalImageViewOptions {
  WindowWidth: number;
  WindowLevel: number;
  Sharpness: number;
  Invert: boolean;
  ColorMap: MedicalImageColorMap | undefined;
  ViewportCoords: MedicalImageViewportCoords | undefined;
}

/** TODO: @kirill */
export interface MedicalImageColorMap {}

export interface MedicalImageViewportCoords {
  Left: number;
  Top: number;
  Right: number;
  Bottom: number;
}

function createBaseMedicalImageViewOptions(): MedicalImageViewOptions {
  return {
    WindowWidth: 0,
    WindowLevel: 0,
    Sharpness: 0,
    Invert: false,
    ColorMap: undefined,
    ViewportCoords: undefined,
  };
}

export const MedicalImageViewOptions = {
  encode(
    message: MedicalImageViewOptions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.WindowWidth !== 0) {
      writer.uint32(9).double(message.WindowWidth);
    }
    if (message.WindowLevel !== 0) {
      writer.uint32(17).double(message.WindowLevel);
    }
    if (message.Sharpness !== 0) {
      writer.uint32(25).double(message.Sharpness);
    }
    if (message.Invert === true) {
      writer.uint32(32).bool(message.Invert);
    }
    if (message.ColorMap !== undefined) {
      MedicalImageColorMap.encode(
        message.ColorMap,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.ViewportCoords !== undefined) {
      MedicalImageViewportCoords.encode(
        message.ViewportCoords,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MedicalImageViewOptions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicalImageViewOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.WindowWidth = reader.double();
          break;
        case 2:
          message.WindowLevel = reader.double();
          break;
        case 3:
          message.Sharpness = reader.double();
          break;
        case 4:
          message.Invert = reader.bool();
          break;
        case 5:
          message.ColorMap = MedicalImageColorMap.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 6:
          message.ViewportCoords = MedicalImageViewportCoords.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MedicalImageViewOptions {
    return {
      WindowWidth: isSet(object.WindowWidth) ? Number(object.WindowWidth) : 0,
      WindowLevel: isSet(object.WindowLevel) ? Number(object.WindowLevel) : 0,
      Sharpness: isSet(object.Sharpness) ? Number(object.Sharpness) : 0,
      Invert: isSet(object.Invert) ? Boolean(object.Invert) : false,
      ColorMap: isSet(object.ColorMap)
        ? MedicalImageColorMap.fromJSON(object.ColorMap)
        : undefined,
      ViewportCoords: isSet(object.ViewportCoords)
        ? MedicalImageViewportCoords.fromJSON(object.ViewportCoords)
        : undefined,
    };
  },

  toJSON(message: MedicalImageViewOptions): unknown {
    const obj: any = {};
    message.WindowWidth !== undefined &&
      (obj.WindowWidth = message.WindowWidth);
    message.WindowLevel !== undefined &&
      (obj.WindowLevel = message.WindowLevel);
    message.Sharpness !== undefined && (obj.Sharpness = message.Sharpness);
    message.Invert !== undefined && (obj.Invert = message.Invert);
    message.ColorMap !== undefined &&
      (obj.ColorMap = message.ColorMap
        ? MedicalImageColorMap.toJSON(message.ColorMap)
        : undefined);
    message.ViewportCoords !== undefined &&
      (obj.ViewportCoords = message.ViewportCoords
        ? MedicalImageViewportCoords.toJSON(message.ViewportCoords)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MedicalImageViewOptions>, I>>(
    object: I,
  ): MedicalImageViewOptions {
    const message = createBaseMedicalImageViewOptions();
    message.WindowWidth = object.WindowWidth ?? 0;
    message.WindowLevel = object.WindowLevel ?? 0;
    message.Sharpness = object.Sharpness ?? 0;
    message.Invert = object.Invert ?? false;
    message.ColorMap =
      object.ColorMap !== undefined && object.ColorMap !== null
        ? MedicalImageColorMap.fromPartial(object.ColorMap)
        : undefined;
    message.ViewportCoords =
      object.ViewportCoords !== undefined && object.ViewportCoords !== null
        ? MedicalImageViewportCoords.fromPartial(object.ViewportCoords)
        : undefined;
    return message;
  },
};

function createBaseMedicalImageColorMap(): MedicalImageColorMap {
  return {};
}

export const MedicalImageColorMap = {
  encode(
    _: MedicalImageColorMap,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MedicalImageColorMap {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicalImageColorMap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MedicalImageColorMap {
    return {};
  },

  toJSON(_: MedicalImageColorMap): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MedicalImageColorMap>, I>>(
    _: I,
  ): MedicalImageColorMap {
    const message = createBaseMedicalImageColorMap();
    return message;
  },
};

function createBaseMedicalImageViewportCoords(): MedicalImageViewportCoords {
  return { Left: 0, Top: 0, Right: 0, Bottom: 0 };
}

export const MedicalImageViewportCoords = {
  encode(
    message: MedicalImageViewportCoords,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Left !== 0) {
      writer.uint32(9).double(message.Left);
    }
    if (message.Top !== 0) {
      writer.uint32(17).double(message.Top);
    }
    if (message.Right !== 0) {
      writer.uint32(25).double(message.Right);
    }
    if (message.Bottom !== 0) {
      writer.uint32(33).double(message.Bottom);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MedicalImageViewportCoords {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicalImageViewportCoords();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Left = reader.double();
          break;
        case 2:
          message.Top = reader.double();
          break;
        case 3:
          message.Right = reader.double();
          break;
        case 4:
          message.Bottom = reader.double();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MedicalImageViewportCoords {
    return {
      Left: isSet(object.Left) ? Number(object.Left) : 0,
      Top: isSet(object.Top) ? Number(object.Top) : 0,
      Right: isSet(object.Right) ? Number(object.Right) : 0,
      Bottom: isSet(object.Bottom) ? Number(object.Bottom) : 0,
    };
  },

  toJSON(message: MedicalImageViewportCoords): unknown {
    const obj: any = {};
    message.Left !== undefined && (obj.Left = message.Left);
    message.Top !== undefined && (obj.Top = message.Top);
    message.Right !== undefined && (obj.Right = message.Right);
    message.Bottom !== undefined && (obj.Bottom = message.Bottom);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MedicalImageViewportCoords>, I>>(
    object: I,
  ): MedicalImageViewportCoords {
    const message = createBaseMedicalImageViewportCoords();
    message.Left = object.Left ?? 0;
    message.Top = object.Top ?? 0;
    message.Right = object.Right ?? 0;
    message.Bottom = object.Bottom ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
