import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeepPartial } from 'react-hook-form';
import { BrowserHeaders } from 'browser-headers';

import { ApiError } from '@/shared/api/api';

import { SliceName } from '../config';

type ThunkRequestFunction<Request, Response> = (
  request: Request,
  metadata?: BrowserHeaders,
) => Promise<Response>;

export const createThunkGenerator =
  <RequestsNames>(sliceName: SliceName) =>
  <Request, Response>(
    requestName: RequestsNames,
    requestFunction: ThunkRequestFunction<DeepPartial<Request>, Response>,
  ) =>
    createAsyncThunk(
      `${sliceName}/${requestName}`,
      async (request: DeepPartial<Request>, { rejectWithValue }) => {
        try {
          const response = (await requestFunction(
            request,
          )) as unknown as Response;

          return response;
        } catch (error: unknown) {
          const { message, type } = error as ApiError;

          return rejectWithValue({ message, type });
        }
      },
    );
