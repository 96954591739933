import React from 'react';
import cn from 'classnames';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { Icon } from '../Icon/Icon';

import styles from './Accordion.module.scss';

type AccordionTriggerProps = AccordionPrimitive.AccordionTriggerProps & {
  counter?: number;
};

const InternalAccordionRoot: React.ForwardRefRenderFunction<
  HTMLDivElement,
  | AccordionPrimitive.AccordionSingleProps
  | AccordionPrimitive.AccordionMultipleProps
> = (props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <AccordionPrimitive.Root
      className={cn(styles.container, className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      ref={ref}
    >
      {children}
    </AccordionPrimitive.Root>
  );
};

const InternalAccordionItem: React.ForwardRefRenderFunction<
  HTMLDivElement,
  AccordionPrimitive.AccordionItemProps
> = (props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <AccordionPrimitive.Item
      className={cn(styles.item, className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      ref={ref}
    >
      {children}
    </AccordionPrimitive.Item>
  );
};

const InternalAccordionHeader: React.ForwardRefRenderFunction<
  HTMLHeadingElement,
  AccordionPrimitive.AccordionHeaderProps
> = (props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <AccordionPrimitive.Header
      className={cn(styles.header, className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      ref={ref}
    >
      {children}
    </AccordionPrimitive.Header>
  );
};

const InternalAccordionTrigger: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  AccordionTriggerProps
> = (props, ref) => {
  const { children, className, counter, ...rest } = props;

  return (
    <AccordionPrimitive.Trigger
      className={cn(styles.trigger, className, 'h5')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      ref={ref}
    >
      <Icon className={styles.arrow} name="arrowDown" size={24} />
      <span className={styles.name}>{children}</span>
      {counter !== undefined && (
        <span className={styles.counter}>{counter}</span>
      )}
    </AccordionPrimitive.Trigger>
  );
};

const InternalAccordionContent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  AccordionPrimitive.AccordionContentProps
> = (props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <AccordionPrimitive.Content
      className={cn(styles.content, className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      ref={ref}
    >
      {children}
    </AccordionPrimitive.Content>
  );
};

const AccordionRoot = React.forwardRef<
  HTMLDivElement,
  | AccordionPrimitive.AccordionSingleProps
  | AccordionPrimitive.AccordionMultipleProps
>(InternalAccordionRoot);

const AccordionItem = React.forwardRef<
  HTMLDivElement,
  AccordionPrimitive.AccordionItemProps
>(InternalAccordionItem);

const AccordionHeader = React.forwardRef<
  HTMLHeadingElement,
  AccordionPrimitive.AccordionHeaderProps
>(InternalAccordionHeader);

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(InternalAccordionTrigger);

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  AccordionPrimitive.AccordionContentProps
>(InternalAccordionContent);

export type CompoundedComponent = {
  Root: typeof AccordionPrimitive.Root;
  Item: typeof AccordionPrimitive.Item;
  Header: typeof AccordionPrimitive.Header;
  Trigger: typeof AccordionTrigger;
  Content: typeof AccordionPrimitive.Content;
};

export const Accordion: CompoundedComponent = {
  Root: AccordionRoot,
  Item: AccordionItem,
  Header: AccordionHeader,
  Trigger: AccordionTrigger,
  Content: AccordionContent,
};
