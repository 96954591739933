// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoveDoctorModal-module__container--cqXyDVbM{margin-top:90px}.RemoveDoctorModal-module__modalBody--PJnNku7s{display:flex;flex-direction:column;gap:16px}.RemoveDoctorModal-module__errorText--n3r-yfYm{text-align:center}", "",{"version":3,"sources":["webpack://./src/features/removeDoctor/ui/RemoveDoctorModal/RemoveDoctorModal.module.scss"],"names":[],"mappings":"AAAA,+CACE,eAAA,CAGF,+CACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,+CACE,iBAAA","sourcesContent":[".container {\n  margin-top: 90px;\n}\n\n.modalBody {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.errorText {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RemoveDoctorModal-module__container--cqXyDVbM",
	"modalBody": "RemoveDoctorModal-module__modalBody--PJnNku7s",
	"errorText": "RemoveDoctorModal-module__errorText--n3r-yfYm"
};
export default ___CSS_LOADER_EXPORT___;
