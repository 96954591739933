// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Column-module__column--9ro4xk4S{flex:1;display:flex;flex-direction:column;gap:16px}@media screen and (max-width: 768px){.Column-module__column--9ro4xk4S{flex:auto}}", "",{"version":3,"sources":["webpack://./src/shared/ui/Column/Column.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAEA,iCACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CCOA,qCDXF,iCAOI,SAAA,CAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.column {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  @include tablet {\n    flex: auto;\n  }\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": "Column-module__column--9ro4xk4S"
};
export default ___CSS_LOADER_EXPORT___;
