// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RadioGroup-module__container--ux10SmRg{display:flex;flex-direction:column;gap:8px}.RadioGroup-module__innerContainer--MRH8W0Lx{display:flex;gap:16px}.RadioGroup-module__innerContainer--MRH8W0Lx.RadioGroup-module__variantTab--34vC\\+luW{gap:8px}", "",{"version":3,"sources":["webpack://./src/shared/ui/RadioGroup/RadioGroup.module.scss"],"names":[],"mappings":"AAAA,wCACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,6CACE,YAAA,CACA,QAAA,CAEA,sFACE,OAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.innerContainer {\n  display: flex;\n  gap: 16px;\n\n  &.variantTab {\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RadioGroup-module__container--ux10SmRg",
	"innerContainer": "RadioGroup-module__innerContainer--MRH8W0Lx",
	"variantTab": "RadioGroup-module__variantTab--34vC+luW"
};
export default ___CSS_LOADER_EXPORT___;
