// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Spinner-module__spinner--Wh0-5QJL{animation:Spinner-module__spin--h4P89Stj 1s linear infinite;fill:transparent}.Spinner-module__spinner--Wh0-5QJL.Spinner-module__primary--taYFA1GT{color:var(--purpleflat)}@keyframes Spinner-module__spin--h4P89Stj{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./src/shared/ui/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA,mCACE,2DAAA,CACA,gBAAA,CAEA,qEACE,uBAAA,CAIJ,0CACE,KACE,sBAAA,CAGF,GACE,wBAAA,CAAA","sourcesContent":[".spinner {\n  animation: spin 1s linear infinite;\n  fill: transparent;\n\n  &.primary {\n    color: var(--purpleflat);\n  }\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": "Spinner-module__spinner--Wh0-5QJL",
	"spin": "Spinner-module__spin--h4P89Stj",
	"primary": "Spinner-module__primary--taYFA1GT"
};
export default ___CSS_LOADER_EXPORT___;
