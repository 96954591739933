// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SkeletonRow-module__container--ryO6MhNu{max-width:1280px;display:grid;gap:30px}.SkeletonRow-module__rowWrapper--ivOlBpR2{display:flex;align-items:center;gap:12px}@media screen and (max-width: 420px){.SkeletonRow-module__rowWrapper--ivOlBpR2:nth-child(n+5){display:none}}.SkeletonRow-module__innerWrapper--30-u1OyE{width:calc(100% - 92px);display:grid;justify-content:space-between;align-items:center;grid-template-columns:2.4fr 1fr .5fr;gap:2.9874213836%}", "",{"version":3,"sources":["webpack://./src/shared/ui/Skeleton/components/SkeletonRow/SkeletonRow.module.scss","webpack://./src/shared/styles/media.scss"],"names":[],"mappings":"AAKA,yCACE,gBCHQ,CDIR,YAAA,CACA,QAAA,CAGF,0CACE,YAAA,CACA,kBAAA,CACA,QAAA,CCPA,qCDUE,yDACE,YAAA,CAAA,CAKN,4CACE,uBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,oCAAA,CACA,iBAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n$elementWidth: 1272;\n$avatarAndGap: 92px;\n\n.container {\n  max-width: $desktop;\n  display: grid;\n  gap: 30px;\n}\n\n.rowWrapper {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n\n  @include mobile {\n    &:nth-child(n + 5) {\n      display: none;\n    }\n  }\n}\n\n.innerWrapper {\n  width: calc(100% - $avatarAndGap);\n  display: grid;\n  justify-content: space-between;\n  align-items: center;\n  grid-template-columns: 2.4fr 1fr 0.5fr;\n  gap: calc(38% / ($elementWidth / 100));\n}\n","$mobile: 420px;\n$tablet: 768px;\n$smallDesktop: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1400px;\n\n@mixin mobile {\n  @media screen and (max-width: $mobile) {\n    @content;\n  }\n}\n\n@mixin tablet {\n  @media screen and (max-width: $tablet) {\n    @content;\n  }\n}\n\n@mixin smallDesktop {\n  @media screen and (max-width: $smallDesktop) {\n    @content;\n  }\n}\n\n@mixin desktop {\n  @media screen and (max-width: $desktop) {\n    @content;\n  }\n}\n\n@mixin largeDesktop {\n  @media screen and (max-width: $largeDesktop) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SkeletonRow-module__container--ryO6MhNu",
	"rowWrapper": "SkeletonRow-module__rowWrapper--ivOlBpR2",
	"innerWrapper": "SkeletonRow-module__innerWrapper--30-u1OyE"
};
export default ___CSS_LOADER_EXPORT___;
