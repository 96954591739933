import { useEffect, useState } from 'react';

import { useMedia } from './useMedia';

interface MedicalImageContainerSize {
  imageContainerWidth: number;
  imageContainerHeight: number;
}

export const useMedicalImageContainerSize = (
  imageWidth: number,
  imageHeight: number,
): MedicalImageContainerSize => {
  const { isPhone, isLarge, isWide } = useMedia();

  const [clientSize, setСlientSize] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  });

  const resizeHandle = () => {
    setСlientSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandle);
    return () => {
      window.removeEventListener('resize', resizeHandle);
    };
  }, []);

  const padding = isPhone ? 16 : 48;

  const proportionOfHeight = imageHeight / imageWidth;

  const hasDimensions = imageWidth !== undefined && imageHeight !== undefined;

  const containerWidth = clientSize.width - padding;

  if (!hasDimensions) {
    return {
      imageContainerWidth: 0,
      imageContainerHeight: 0,
    };
  }

  if (isLarge || isWide) {
    return {
      imageContainerWidth: imageWidth,
      imageContainerHeight: imageHeight,
    };
  }

  return {
    imageContainerWidth: containerWidth,
    imageContainerHeight: containerWidth * proportionOfHeight,
  };
};
