// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dropdown-module__container--LmFAIpmn{position:relative}.Dropdown-module__trigger--RU7aSwb-{cursor:pointer}.Dropdown-module__children--sKqz1WD9{z-index:1;position:absolute}.Dropdown-module__children--sKqz1WD9 button:hover{background-color:rgb(var(--purpleflat-rgb), 8%);transition:background-color .5s ease}.Dropdown-module__children--sKqz1WD9 button{background-color:transparent;border:none;width:100%}", "",{"version":3,"sources":["webpack://./src/shared/ui/Dropdown/Dropdown.module.scss"],"names":[],"mappings":"AAAA,sCACE,iBAAA,CAGF,oCACE,cAAA,CAGF,qCACE,SAAA,CACA,iBAAA,CAEA,kDACE,+CAAA,CACA,oCAAA,CAGF,4CACE,4BAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".container {\n  position: relative;\n}\n\n.trigger {\n  cursor: pointer;\n}\n\n.children {\n  z-index: 1;\n  position: absolute;\n\n  & button:hover {\n    background-color: rgb(var(--purpleflat-rgb), 8%);\n    transition: background-color 0.5s ease;\n  }\n\n  & button {\n    background-color: transparent;\n    border: none;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Dropdown-module__container--LmFAIpmn",
	"trigger": "Dropdown-module__trigger--RU7aSwb-",
	"children": "Dropdown-module__children--sKqz1WD9"
};
export default ___CSS_LOADER_EXPORT___;
