import { compose, find, pathOr, propEq } from 'ramda';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

export const findConditionProbabilityByCode =
  (code: ConditionCode) => (conditions: Condition[]) =>
    compose(
      pathOr(0, ['Certainty', 'ModelScore']),
      find(propEq<keyof Condition>('Code')(code)),
    )(conditions);
