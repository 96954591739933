// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Content-module__container--43WSUknb{grid-area:content;display:flex}", "",{"version":3,"sources":["webpack://./src/shared/ui/Layout/components/Content/Content.module.scss"],"names":[],"mappings":"AAEA,qCACE,iBAAA,CACA,YAAA","sourcesContent":["@import '@/shared/styles/media.scss';\n\n.container {\n  grid-area: content;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Content-module__container--43WSUknb"
};
export default ___CSS_LOADER_EXPORT___;
