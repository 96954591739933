// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Divider-module__container--emdAnpVG{width:100%;height:1px;background-color:var(--bg5)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Divider/Divider.module.scss"],"names":[],"mappings":"AAAA,qCACE,UAAA,CACA,UAAA,CACA,2BAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 1px;\n  background-color: var(--bg5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Divider-module__container--emdAnpVG"
};
export default ___CSS_LOADER_EXPORT___;
