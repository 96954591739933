// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Chip-module__container--cpqHt1aR{background-color:var(--white);display:flex;justify-content:center;align-items:center;border-radius:100px;padding:1px 6px}.Chip-module__label--qFkYxo1X{color:var(--purpletext2)}", "",{"version":3,"sources":["webpack://./src/shared/ui/Chip/Chip.module.scss"],"names":[],"mappings":"AAAA,kCACE,6BAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,mBAAA,CACA,eAAA,CAGF,8BACE,wBAAA","sourcesContent":[".container {\n  background-color: var(--white);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 100px;\n  padding: 1px 6px;\n}\n\n.label {\n  color: var(--purpletext2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Chip-module__container--cpqHt1aR",
	"label": "Chip-module__label--qFkYxo1X"
};
export default ___CSS_LOADER_EXPORT___;
