// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StudySelectOption-module__container--0C9qKbBb{display:flex;align-items:center;gap:8px}.StudySelectOption-module__patientID--jiVRR78a{color:var(--grey8)}", "",{"version":3,"sources":["webpack://./src/entities/study/ui/StudySelectOption/StudySelectOption.module.scss"],"names":[],"mappings":"AAAA,+CACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,+CACE,kBAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.patientID {\n  color: var(--grey8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "StudySelectOption-module__container--0C9qKbBb",
	"patientID": "StudySelectOption-module__patientID--jiVRR78a"
};
export default ___CSS_LOADER_EXPORT___;
