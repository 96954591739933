import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { Button, Dropdown, Popup, Icon } from '@/shared/ui';

import styles from './MenuButton.module.scss';

type MenuButtonsVariants = 'row' | 'card';

export type MenuButtonProps = {
  onEdit: () => void;
  onRemove: () => void;
  type?: MenuButtonsVariants;
  className?: string;
};

export const MenuButton: FC<MenuButtonProps> = (props) => {
  const { onEdit, onRemove, className, type = 'row' } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const editHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onEdit();
    setIsOpen(false);
  };

  const removeHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setConfirmIsOpen(true);
    setIsOpen(false);
  };

  return (
    <div className={className}>
      <Dropdown
        trigger={<Icon name="arrowDown" size={32} />}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        className={cn(styles.icon, type === 'card' && styles.card)}
      >
        <div className={styles.dropdown}>
          <button
            className={styles.setting}
            onClick={editHandler}
            type="button"
          >
            <Icon name="pen" size={24} />
            <span className="p2">
              <FormattedMessage id="dropdown.edit" defaultMessage="Edit" />
            </span>
          </button>
          <button
            type="button"
            className={cn(styles.setting, styles.delete)}
            onClick={removeHandler}
          >
            <Icon name="delete" size={24} />
            <span className="p2">
              <FormattedMessage id="dropdown.remove" defaultMessage="Remove" />
            </span>
          </button>
        </div>
      </Dropdown>
      {confirmIsOpen && (
        <Popup
          isOpen
          onClose={() => setConfirmIsOpen(false)}
          className={styles.popup}
        >
          <p className={cn('h2', styles.popupTitle)}>
            <FormattedMessage
              id="patientList.confirm.message"
              defaultMessage="Are you sure you want to remove the patient?"
            />
          </p>
          <div className={styles.popupButtons}>
            <Button
              variant="tertiary"
              size="small"
              onClick={() => {
                setConfirmIsOpen(false);
                setIsOpen(false);
              }}
            >
              <FormattedMessage
                id="patientList.confirm.cancelButton"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              className={styles.confirmButton}
              onClick={() => {
                onRemove();
                setConfirmIsOpen(false);
                setIsOpen(false);
              }}
            >
              <FormattedMessage
                id="patientList.confirm.okButton"
                defaultMessage="OK"
              />
            </Button>
          </div>
        </Popup>
      )}
    </div>
  );
};
